import React, { useRef } from 'react'
import clsx from 'clsx'
import { PageProps } from 'gatsby'
import { Layout } from '../../components/layout'
import { SEO } from '../../components/seo'
import { Menu } from '../../components/menu'
import { useMediaQuery } from '../../utils/hooks'

import * as styles from '../../styles/pages/about.module.scss'

import about from '../../images/about.png'
import aboutLine from '../../images/about2.png'
import aboutEnd from '../../images/about3.png'
import superacao from '../../images/superacaoBanner.png'
import jump from '../../images/about4.png'
import logo from '../../images/logo-colorido.svg'
import manifest from '../../images/manifest.png'
import { Contact } from '../../components/contact/contact'
import { Footer } from '../../components/footer'

const AboutPage: React.FC<PageProps> = ({ location }) => {
  const aboutBannerRef = useRef<HTMLDivElement>(null)
  const isMobile = useMediaQuery(`768`)

  return (
    <Layout location={location}>
      <SEO title="High Jump - Quem Somos" />
      <div className={styles.about}>
        <Menu mainFolder="/quem-somos" />
        <main>
          <div className={styles.nextJump} ref={aboutBannerRef}>
            <img src={jump} alt="next jump" />
          </div>

          <div className={styles.container}>
            <section className={styles.about} id="about">
              <div className="container">
                <div className="row">
                  <div className={clsx('col-12 col-lg-6')}>
                    <div className={styles.aboutContent}>
                      <div className={styles.innerTitle}>
                        <h2>
                          Se pode ser <br />
                          medido, <br />
                          pode ser <br />
                          <span className={styles.highlight}>melhorado.</span>
                        </h2>
                      </div>
                      <div className={styles.text}>
                        <p>
                          A High Jump é especializada em Gestão por Processos de
                          Negócio BPM (Business Process Management). Oferece
                          soluções do{' '}
                          <span className={styles.highlight}>mapeamento </span>{' '}
                          <span className={styles.highlight}>
                            à melhoria de processos{' '}
                          </span>{' '}
                          a empresas de diversos portes e segmentos. Foi
                          estruturada a partir da Valore Brasil, consultoria com
                          destacada presença em todo o país.
                        </p>
                      </div>
                      <div className={styles.text}>
                        <p>
                          A expertise da High Jump é alicerçada em mais de 12
                          anos de atuação. Nossa equipe multidisciplinar
                          desenvolve projetos utilizando metodologias ágeis, de
                          forma prática e inovadora, integrando Processos,
                          Qualidade, Compliance e Tecnologia.
                        </p>
                      </div>
                    </div>
                    {!isMobile && (
                      <div className={styles.aboutImages}>
                        <div className={styles.lineImg}>
                          <img src={aboutLine} alt="Highjump about lines" />
                        </div>
                        <div className={styles.singleImg}>
                          <img src={aboutEnd} alt="Highjump about end" />
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className={styles.aboutImg}>
                      <img src={about} alt="Highjump about" />
                    </div>

                    <div className={clsx(styles.aboutContent, styles.longText)}>
                      <div className={styles.innerTitle}>
                        <h2>
                          Eleve a barra. <br />
                          Basta seguir os <br />
                          <span className={styles.highlight}>
                            passos certos.
                          </span>
                        </h2>
                      </div>

                      <div className={styles.text}>
                        <p>
                          A High Jump carrega no seu DNA a inovação na melhoria
                          de processos, fornecendo{' '}
                          <span className={styles.highlight}>
                            soluções para ressignificar
                          </span>{' '}
                          negócios diante das crescentes demandas do mercado.
                        </p>
                        <br />
                        <p>
                          A nova marca é resultado da multiplicação de dois
                          grandes pilares de atuação da{' '}
                          <strong>Valore Brasil</strong>. Em 2015, sob a gestão
                          da mesma, constituiu-se a{' '}
                          <strong>Valore Avaliações</strong> Especializada em
                          Valuation e M&A e a <strong>Valore Melhoria</strong>{' '}
                          Especializada em Melhoria de Processos.
                        </p>
                        <br />
                        <p>
                          Diante da evolução do mercado e a incorporação de
                          novas soluções, surgiu a necessidade de criar uma
                          marca que destacasse a essência da Valore Melhoria,
                          nascendo então a High Jump!
                        </p>
                        <br />
                        <p>
                          Sob a liderança do Diretor Executivo{' '}
                          <span className={styles.highlight}>Adiel Lima</span>,
                          a companhia é resultado de experiências agregadas em
                          centenas de projetos, que proporcionaram{' '}
                          <span className={styles.highlight}>
                            cerca de 3.000 melhorias
                          </span>{' '}
                          de processos.
                        </p>
                        <br />
                        <p>
                          Atua com metodologia própria, reunindo e aplicando as
                          melhores práticas dos mercados nacional e
                          internacional.
                        </p>
                        <br />
                        <p>
                          O trabalho é fundamentado em quatro etapas:
                          Planejamento, Mapeamento, Análise e Melhoria e
                          Implementação. Após conhecer as expectativas dos
                          clientes, são mapeadas suas necessidades e
                          implementados planos de ação para que se alcancem os
                          objetivos pretendidos.
                        </p>
                        <br />
                        <p>
                          <span className={styles.highlight}>
                            O trabalho resulta na efetiva mudança
                            organizacional.
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className={styles.aboutBanner}>
                      <img src={superacao} alt="superacao" />
                    </div>
                  </div>
                </div>
              </div>
            </section>

            <section className={styles.manifest} id="manifest">
              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className={styles.innerContent}>
                    <img src={logo} alt="high jump" />
                  </div>
                  <div className={styles.manifestImage}>
                    <img src={manifest} alt="Richard Douglas Fosbury" />
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className={styles.innerContent}>
                    <div className={styles.title}>
                      <h3>Manifesto / Inspiraçāo</h3>
                    </div>

                    <div className={styles.text}>
                      <p>
                        A identidade da High Jump é inspirada na trajetória de
                        Richard Douglas Fosbury. O atleta norte-americano,
                        nascido em 6 de Março de 1947, revolucionou o salto em
                        altura.
                      </p>
                    </div>
                    <div className={styles.text}>
                      <p>
                        Dick Fosbury conquistou a medalha de ouro nos Jogos
                        Olímpicos de 1968, realizados no México, com a técnica
                        de saltar de costas.
                      </p>
                    </div>
                    <div className={styles.text}>
                      <p>
                        Com o "Salto Fosbury", ele estabeleceu um novo recorde
                        olímpico. E mudou para sempre a história da modalidade
                        esportiva.
                      </p>
                    </div>
                    <div className={styles.text}>
                      <p>
                        Especialistas afirmam que Fosbury é responsável pelo
                        momento de transformação mais radical do atletismo.
                      </p>
                    </div>
                    <div className={clsx(styles.text, styles.lines)}>
                      <p>
                        Para a Highjump elevar a barra de qualidade é nossa
                        maior motivação - transformar sua empresa, com um salto
                        para a excelência.
                      </p>
                      <p>
                        Porque se é bom pode ser ótimo.
                        <br /> Se já é ótimo, pode ser excelente.
                        <br /> Melhoria sempre, esse é o foco.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <div className={styles.divider}></div>

          <Contact />
          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default AboutPage
